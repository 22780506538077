import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RightSidebarItems from "./RightSidebarItems";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: "inline-block",
    width: "280px",
    height: "100%",
    float: "right",
    clear: "both",
  },
}));

function RightSidebarDesktop() {
  const classes = useStyles();

  return (
    <div className={classes.sidebar}>
      <RightSidebarItems headingSize=".9rem" />
    </div>
  );
}

export default RightSidebarDesktop;
