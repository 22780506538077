import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import {
  fade,
  makeStyles,
  Avatar,
  Typography,
  Card,
  Badge,
} from "@material-ui/core";
import QuestionAnswerTwoToneIcon from "@material-ui/icons/QuestionAnswerTwoTone";
import IconButton from "@material-ui/core/IconButton";
import CompareArrowsTwoToneIcon from "@material-ui/icons/CompareArrowsTwoTone";
import ThumbDownAltTwoToneIcon from "@material-ui/icons/ThumbDownAltTwoTone";
import FavoriteTwoToneIcon from "@material-ui/icons/FavoriteTwoTone";

const useStyles = makeStyles((theme) => ({
  post: {
    height: "100%",
    width: "100%",
    marginTop: theme.spacing(2),
    margin: "auto",
    backgroundColor: fade(theme.palette.background.paper, 0.6),
    "&:hover": {
      backgroundColor: fade(theme.palette.background.paper, 0.6),
    },
    borderRadius: "0px",
    boxShadow: "2px 8px 11px -1px rgba(0,0,0,0.43)",
    color: theme.palette.text.primary,
  },
  postContent: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  postBody: {
    fontSize: "1.6rem",
    textAlign: "justify",
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
  },
  profilePic: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    marginTop: theme.spacing(0.5),
    border: `.5px solid ${theme.palette.secondary.main}`,
  },
  userName: {
    fontSize: "2rem",
    marginLeft: theme.spacing(1.5),
    textShadow: "1px 1px 0 #212121",
  },
  screenName: {
    marginLeft: theme.spacing(1.5),
  
    color: theme.palette.text.secondary,
  },
  time: {
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(1.5),
    marginLeft: "auto",
  },
  topRow: {
    display: "flex",
    marginBottom: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
  },
  badge: {
    marginLeft: theme.spacing(9.5),
    marginRight: theme.spacing(9.5),
  },
  customBadge: {
    fontSize: "1.25rem",
  },
  button: {
    display: "inline-block",
    color: theme.palette.text.disabled,
    fontSize: "3rem",
  },
  icon: {
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.25)",
    marginLeft: "10px",
    border: `solid 1px ${theme.palette.secondary.main}`,
  },
  container: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  likeCount: {
    display: "inline-block",
    color: theme.palette.text.secondary,
    fontFamily: "Kanit",
    fontSize: "1.4rem",
    textAlign: "left",
    width: "40px",
    marginRight: "9px",
    marginTop: "20px",
    paddingLeft: "10px",
  },
}));

function Thought(props) {
  const classes = useStyles();

  const postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  const handleLike = useCallback((thoughtID) => {
    return async (e) => {
    console.log("handle like button")
    console.log(thoughtID)
    e.preventDefault();
    const formData = {
      thoughtID: thoughtID
    }
    postData("/api/thoughts/add_like", formData);
    }
  }, [])

  const handleDislike = useCallback((thoughtID) => {
    return async (e) => {
    console.log("handle dislike button")
    console.log(thoughtID)
    e.preventDefault();
    const formData = {
      thoughtID: thoughtID
    }
    postData("/api/thoughts/add_dislike", formData);
    }
  }, [])


  return (
    <Card className={classes.post}>
      <div className={classes.postContent}>
        <div className={classes.topRow}>
        <Link style={{display: "flex", color: "inherit", textDecoration: "none"}} to={props.screenName}>
          <Avatar className={classes.profilePic} src={props.profilePic} />
          <div>
            <Typography
              className={classes.userName}
              style={{ color: `${props.userColor}` }}
            >
              {props.userName}
            </Typography>
            <Typography className={classes.screenName}>
              @{props.screenName}
            </Typography>
          </div>
        </Link>
          <Typography className={classes.time}>{props.time.substr(0, 10)}</Typography>
        </div>
        <Typography className={classes.postBody}>{props.body}</Typography>
      </div>
      <div className={classes.container}>
        <Badge
          classes={{ badge: classes.customBadge }}
          className={classes.badge}
          badgeContent={props.comments}
          color="secondary"
          overlap="circle"
          style={{ fontSize: "4rem" }}
        >
          <IconButton size="" className={classes.icon}>
            <QuestionAnswerTwoToneIcon className={classes.button} />
          </IconButton>
        </Badge>
        <Badge
          classes={{ badge: classes.customBadge }}
          className={classes.badge}
          badgeContent={props.dislikes}
          color="secondary"
          overlap="circle"
          style={{ fontSize: "4rem" }}
        >
          <IconButton size="" className={classes.icon} onClick={handleDislike(props.thoughtID)}>
            <ThumbDownAltTwoToneIcon className={classes.button} />
          </IconButton>
        </Badge>
        <Badge
          classes={{ badge: classes.customBadge }}
          className={classes.badge}
          badgeContent={props.likes}
          color="secondary"
          overlap="circle"
          style={{ fontSize: "4rem" }}
        >
          <IconButton size="" className={classes.icon} onClick={handleLike(props.thoughtID)}>
            <FavoriteTwoToneIcon className={classes.button} />
          </IconButton>
        </Badge>
      </div>
    </Card>
  );
}

export default Thought;
