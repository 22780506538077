import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import Thought from "./Thought";
import CreateThought from "./CreateThought";
import {CurrentUserContext} from "../App"

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "750px",
  },
}));

function InformalPosts() {
  const classes = useStyles();
  const currentUser = useContext(CurrentUserContext);
  const thoughtsPerPage = 50;
  const [thoughts, setThoughts] = useState([]);
  const [page, setPage] = React.useState(1);
  const [numPages, setNumPages] = React.useState(
    Math.ceil(thoughts.length / thoughtsPerPage)
  );
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/thoughts/get");
      // const likedPostsResponse = await fetch("/api/articles/get_current_user_likes");
      // const likedPostsData = await likedPostsResponse.json();
      // console.log(likedPostsData.rows);
      const data = await response.json();
      setThoughts(data.rows);
      console.log(data.rows);
      console.log("FETCHDATA EFFECT");
      console.log(thoughts);
      setNumPages(Math.ceil(data.rows.length / thoughtsPerPage));
    };


    fetchData();
  }, []);


  return (
    <div className={classes.container}>
    {(() => {
      if(currentUser.id !== "notloggedin") return (
        <CreateThought />
      )
    })()}
      {thoughts
        .slice((page - 1) * thoughtsPerPage, page * thoughtsPerPage)
        .map((thought) => (
          <React.Fragment>
            <Thought
              userName={`${thought.first_name} ${thought.last_name}`}
              screenName={thought.screen_name}
              profilePic="https://images.unsplash.com/photo-1519058082700-08a0b56da9b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
              userColor={thought.color === undefined ? "white" : thought.color }
              time={thought.date_posted}
              body={thought.body}
              thoughtID={thought.thought_id}
              likes={thought.likes}
              dislikes={thought.dislikes}
              comments="0"
            />
          </React.Fragment>
        ))}
      <Thought
        userName="Julius Tanner"
        screenName="BUILTLIKEBRINKS"
        profilePic="https://images.unsplash.com/photo-1519058082700-08a0b56da9b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
        userColor=""
        time="May 10, 2020 11:15:57 AM"
        body="my dad met my mom for a second, lost her number, remembered she was a nurse, and then called every hospital in the state to ask her out and I can’t even get a text back 😭"
        likes="19"
        shares="3"
        comments="5"
      />
      <Thought
        userName="Lilly Britt"
        screenName="AlpenGlowWine"
        profilePic="https://images.unsplash.com/photo-1489424731084-a5d8b219a5bb?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
        userColor=""
        time="May 10, 2020 9:10:05 AM"
        body="if your mom ever welcomed me inside their home, fed me or gave me a ride wish them a Happy Mother’s Day from me ❤️"
        likes="80"
        shares="10"
        comments="35"
      />
      <Thought
        userName="Dougie Spooner"
        screenName="bigsp00n"
        profilePic="https://images.unsplash.com/photo-1482264851290-446b18e3ee9f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
        userColor=""
        time="May 10, 2020 8:47:09 AM"
        body="I think education with the right dream still gives a great deal to the world through its students."
        likes="4"
        shares="1"
        comments="3"
      />
      <Thought
        userName="Amba Moyer"
        screenName="beadlikeamba"
        userColor=""
        profilePic="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
        time="May 9, 2020 7:39:25 AM"
        body="Using a laptop makes it look like you're doing work or something important while being on your phone looks the opposite"
        likes="36"
        shares="2"
        comments="7"
      />
      <Thought
        userName="Khalil Cervantes"
        screenName="fightercoke"
        profilePic="https://images.unsplash.com/photo-1531901599143-df5010ab9438?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
        userColor=""
        time="May 8, 2020 6:01:44 AM"
        body="Had a great trip with the family last weekend, we saw some beautiful paintings at an exhibit in the biggest museum in the city!"
        likes="16"
        shares="0"
        comments="5"
      />
      <Thought
        userName="Mariam Whitaker"
        screenName="AmericanBeautyGemini"
        profilePic="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
        userColor=""
        time="May 7, 2020 7:31:21 PM"
        body="Today's one of those days where I just keep grabbing another cup of coffee. I'm usually good after 2 or 3. Nope, 5 isn't going to cut it."
        likes="6"
        shares="0"
        comments="1"
      />
      <Thought
        userName="Keane Bannister"
        screenName="spiritsevensamurai"
        profilePic="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80"
        userColor=""
        time="May 7, 2020 5:19:25 AM"
        body="Having one bar of wifi is worse than having no wifi at all"
        likes="15"
        shares="1"
        comments="2"
      />
    </div>
  );
}

export default InformalPosts;
