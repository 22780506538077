import React from "react";
import {
  makeStyles,
  fade,
  Avatar,
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { CurrentUserContext } from "../App";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: fade(theme.palette.background.paper, 0.6),
    marginTop: theme.spacing(2),
    boxShadow: "2px 8px 11px -1px rgba(0,0,0,0.43)",
    borderRadius: "0px",
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  topRow: {
    display: "flex",
  },
  profilePic: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    border: `.5px solid ${theme.palette.secondary.main}`,
  },
  userName: {
    fontSize: "2rem",
    marginLeft: theme.spacing(1.5),
  },
  screenName: {
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
  createThoughtButton: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    float: "right",
    color: theme.palette.primary.main,
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.25)",
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: "0px",
    backgroundColor: "transparent",
    fontSize: "1rem",
  },
}));

function CreateThought() {
  const classes = useStyles();
  const currentUser = React.useContext(CurrentUserContext);

  const [thoughtBody, setThoughtBody] = React.useState("");
  const handleChange = (e) => {
    const { value } = e.target;
    setThoughtBody(value)
  }

  const postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  const handleThoughtSubmit = (e) => {
    e.preventDefault();
    const formData = {
      body: e.target.body.value,
    };
    postData("/api/thoughts/submit", formData);
    setThoughtBody("")
  };
  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <div className={classes.topRow}>
          <Avatar
            className={classes.profilePic}
            src="https://images.unsplash.com/photo-1508310621848-9b567e9621bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1317&q=80"
          />
          <Typography className={classes.userName}>{`${currentUser.first_name} ${currentUser.last_name}`}</Typography>
          <Typography className={classes.screenName}>{`@${currentUser.screen_name}`}</Typography>
        </div>
        <ValidatorForm onSubmit={handleThoughtSubmit} id="thoughtForm">
          <TextValidator
            className={classes.inputContainer}
            margin="normal"
            id="body"
            label="What are you thinking about?"
            type="text"
            variant="outlined"
            fullWidth
            multiline
            onChange={handleChange}
            value={thoughtBody}
            inputProps={{ className: classes.inputField }}
          />
        </ValidatorForm>
        <Button
          onClick=""
          type="submit"
          form="thoughtForm"
          className={classes.createThoughtButton}
        >
          Create Thought
        </Button>
      </CardContent>
    </Card>
  );
}

export default CreateThought;
