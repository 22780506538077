import React from "react";
import { makeStyles, fade, Avatar, Card, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme => ({
  container: { 
    display: "flex",
    height: "100%",
    marginTop: theme.spacing(1),
    backgroundColor: fade(theme.palette.background.paper, 0.6),
    borderRadius: "0px",
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
  },
  rightSideContainer: {
    marginLeft: "auto",
    marginTop: theme.spacing(1)
  },
  followButton: {
    fontSize: "1.25rem",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "0px",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.25)",
  },
  unfollowButton: {
    fontSize: "1.25rem",
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "0px",
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.25)",
  },
  followsYou: {
    display: "inline-block",
    margin: theme.spacing(1.5),
    fontStyle: "italic",
    color: theme.palette.text.disabled,
  },
  userName: {
    fontSize: "1.5rem",

  },
  screenName: {
    color: theme.palette.text.secondary,
    fontSize: "1.25rem",
  },
  profilePic: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    border: `.5px solid ${theme.palette.secondary.main}`,
    marginRight: theme.spacing(1.5),
  }
})))

function ProfileGlimpseFollower(props) {
  const classes = useStyles()

  return (
    <Card className={classes.container}>
      <Avatar className={classes.profilePic}/>
      <div>
        <Typography className={classes.userName}>{props.userName}</Typography>
        <Typography className={classes.screenName}>@{props.screenName}</Typography>
      </div>
      <div className={classes.rightSideContainer}>
      {(() => {
        if(props.followsYou) {
          return <Typography className={classes.followsYou}>follows you</Typography>
        }
      })()}
      {(() => {
        if(props.following) {
          return <Button className={classes.unfollowButton}>UNFOLLOW</Button>
        }
        else return <Button className={classes.followButton}>FOLLOW</Button>
      })()}
      </div>
    </Card>
  )
}

export default ProfileGlimpseFollower