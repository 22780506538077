import React from "react";
import {
  makeStyles,
  Typography,
  ListItem,
  List,
  Avatar,
  Button,
  InputBase,
  fade,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import SecondaryPageTitle from "./SecondaryPageTitle";

const useStyles = makeStyles((theme) => ({
  buttonActive: {
    fontSize: "1.75rem",
    width: "400px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)",
    textShadow: "1px 1px rgba(0, 0, 0, 0.25)",
    margin: theme.spacing(1),
    padding: "0px 10px",
    borderRadius: "0px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  activeUnderline: {
    height: "10px",
    width: "420px",
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  title: {
    display: "flex",
    borderBottom: ".3px solid #909090",
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  item: {
    color: theme.palette.text.primary,
  },
  profilePic: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    marginRight: theme.spacing(1),
    border: `.5px solid ${theme.palette.secondary.main}`,
  },
  timestamp: {
    color: theme.palette.text.disabled,
    marginLeft: "auto",
    minWidth: "6ch",
    paddingLeft: theme.spacing(2),
  },
  nameContainer: {
    whiteSpace: "noWrap",
  },
  personName: {
    fontSize: "1.25rem",
    overflow: "hidden",
    maxWidth: "200px",
    textOverflow: "ellipsis",
  },
  username: {
    fontSize: "1.25rem",
    color: theme.palette.text.disabled,
    overflow: "hidden",
    maxWidth: "200px",
    textOverflow: "ellipsis",
  },
  messageContent: {
    fontSize: "1.25rem",
    maxWidth: "500px",
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  
}));

function DirectMessagesPage() {
  const classes = useStyles();

  return (
    <div>
      <SecondaryPageTitle 
        title="Direct Messages"
        search
        searchPlaceholder="Search Direct Messages…"
      />
      <List>
        <DirectMessageGlimpse
          personName="Justin Wong"
          username="JWONG258"
          profilePic="https://images.unsplash.com/photo-1542327821-87a5f0fb3c9f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80"
          messageContent="The crux of the issue is that the selected menu is used for item
        selection, while the simple menu is used for positionming."
          timestamp="Jun 10"
        />
        <DirectMessageGlimpse
          personName="James Polk"
          username="PREZZY123"
          profilePic="https://images.unsplash.com/photo-1522508042487-6e6684f78c5b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
          messageContent="That is set using the selected property (from ListItem). To use a selected menu item without impacting the"
          timestamp="Jun 07"
        />
        <DirectMessageGlimpse
          personName="Amber Jones"
          username="Torchqueen42"
          profilePic="https://images.unsplash.com/photo-1522602724102-7b966b111376?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
          messageContent="For answering those needs, we expose a MenuList component that you can compose, with Popper in this example.."
          timestamp="Jun 06"
        />
        <DirectMessageGlimpse
          personName="Justin Wong"
          username="JWONG258"
          messageContent="The crux of the issue is that the selected menu is used for item
        selection, while the simple menu is used for positionming."
          timestamp="Jun 06"
        />
        <DirectMessageGlimpse
          personName="Justin Wong"
          username="JWONG258"
          messageContent="The crux of the issue is that the selected menu is used for item
        selection, while the simple menu is used for positionming."
          timestamp="Jun 01"
        />
      </List>
    </div>
  );
}

function DirectMessageGlimpse(props) {
  const classes = useStyles();

  return (
    <ListItem className={classes.item} button component={Link} to={"#"} divider>
      <Avatar className={classes.profilePic} src={props.profilePic} />
      <div className={classes.nameContainer}>
        <Typography className={classes.personName}>
          {props.personName}
        </Typography>
        <Typography className={classes.username}>@{props.username}</Typography>
      </div>
      <Typography className={classes.messageContent}>
        {props.messageContent}
      </Typography>
      <Typography className={classes.timestamp}>{props.timestamp}</Typography>
    </ListItem>
  );
}

export default DirectMessagesPage;
