import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    margin: "auto",
    maxHeight: "325px",
    height: "auto",
    width: "500px",
    backgroundColor: fade(theme.palette.background.paper, 0.6),
    borderRadius: "0px",
    border: "none",
    boxShadow: "none",
  },
  profilePicture: {
    height: "100px",
    width: "100px",
    borderRadius: "50%",
    marginLeft: theme.spacing(3.5),
    marginTop: "25px",
    border: `.5px solid ${theme.palette.secondary.main}`,
  },
  profileInfo: {
    marginTop: "10px",
    width: "65%",
  },
  bio: {
    fontSize: "1.7rem",
    fontStyle: "italic",
  },
  extraInfoLabel: {
    color: theme.palette.text.disabled,
    fontStyle: "bold",
  },
  atName: {
    color: theme.palette.text.secondary,
  },
}));

function ProfileGlimpse() {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <CardMedia
        className={classes.profilePicture}
        image="https://images.unsplash.com/photo-1508310621848-9b567e9621bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1317&q=80"
      ></CardMedia>
      <CardContent className={classes.profileInfo}>
        <Typography variant="h4">Sundar Patel</Typography>
        <Typography className={classes.atName} variant="subtitle1">
          @TOPTIERPLAYER
        </Typography>
        <Typography className={classes.bio} variant="body1">
          Aspiring web developer! I love knowledge and have a passion for
          learning new things.
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ProfileGlimpse;
