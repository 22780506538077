import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import CodeOfConduct from "./CodeOfConduct";
import { Switch, Route } from "react-router-dom";
import FormalPosts from "./FormalPosts";
import InformalPosts from "./InformalPosts";
import FormalityContainer from "./FormalityContainer";
import pageNotFound from "./PageNotFound";
import Contact from "./Contact";
import Mission from "./Mission";
import ProfilePage from "./ProfilePage";
import Chatroom from "./Chatroom";
import SettingsPage from "./SettingsPage";
import LoginPage from "./LoginPage";
import DirectMessagesPage from "./DirectMessagesPage";
import ArchivedDiscussions from "./ArchivedDiscussions";
import ProfileGlimpse from "./ProfileGlimpse";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "750px",
    minHeight: "100vh",
    marginBottom: theme.spacing(4),
  },
}));

function PostsContainers() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Switch>
        <Route exact path="/">
          <ProfileGlimpse />
          <FormalityContainer active />
          <FormalPosts />
        </Route>
        <Route path="/timeline">
          <ProfileGlimpse />
          <FormalityContainer />
          <InformalPosts />
        </Route>
        <Route path="/mission">
          <ProfileGlimpse />
          <Mission />
        </Route>
        <Route path="/codeofconduct">
          <ProfileGlimpse />
          <CodeOfConduct />
        </Route>
        <Route path="/termsofservice">
          <ProfileGlimpse />
          <TermsOfService />
        </Route>
        <Route path="/privacypolicy">
          <ProfileGlimpse />
          <PrivacyPolicy />
        </Route>
        <Route path="/contact">
          <ProfileGlimpse />
          <Contact />
        </Route>
        <Route path="/archived">
          <ProfileGlimpse />
          <ArchivedDiscussions />
        </Route>
        <Route path="/settings">
          <ProfileGlimpse />
          <SettingsPage />
        </Route>
        <Route path="/login">
          <ProfileGlimpse />
          <LoginPage />
        </Route>
        <Route path="/messages">
          <ProfileGlimpse />
          <DirectMessagesPage />
        </Route>
        <Route
          exact
          path="/discussion/:roomId"
          render={(props) => <Chatroom {...props} />}
        />
        <Route
          exact
          path="/:username"
          render={(props) => (
            <React.Fragment>
              <ProfileGlimpse {...props} /> <ProfilePage {...props} />{" "}
            </React.Fragment>
          )}
        />
        <Route component={pageNotFound} />
      </Switch>
    </div>
  );
}

export default PostsContainers;
