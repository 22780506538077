import React from "react";
import { makeStyles } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { Link } from 'react-router-dom';
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import InboxIconTwoTone from "@material-ui/icons/MoveToInboxTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import HomeTwoToneIcon from "@material-ui/icons/HomeTwoTone";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles((theme) => ({
  item: {
    color: theme.palette.text.primary,
    fontSize: "1.25rem",
  },
  subtitle: {
    position: "relative",
    color: theme.palette.text.disabled,
    lineHeight: "36px",
    paddingTop: "6px",
    fontWeight: "bold",
  },
  badge: {
    position: "relative",
    top: "0",
    display: "block",
    paddingBottom: "10px",
    color: "firebrick"
  }
}));

function SidebarNavigation(props) {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{maxHeight: props.maxHeight, overflowY: "auto"}}>
      <ListSubheader
        className={classes.subtitle}
        style={{
          fontSize: props.headingSize,
          paddingLeft: props.padding,
          paddingRight: props.padding,
        }}
      >
        {props.headingName}
      </ListSubheader>

      {props.links.map((link) => (
        
        <ListItem
          className={classes.item}
          button
          key={link.name}
          component={Link}
          to={link.linkPath}
          style={{
            fontSize: props.fontSize,
            color: props.fontColor,
            paddingLeft: props.padding,
            paddingRight: props.padding,
          }}
        >

          {(() => {
            if (props.icons) {
              return (
                <ListItemIcon disableTypography>
                  {(() => {
                    switch (link.name) {
                      case "Home":
                        return (
                          <HomeTwoToneIcon
                            style={{ fontSize: props.fontSize }}
                          />
                        );
                      case "Profile":
                        return (
                          <AccountCircleTwoToneIcon
                            style={{ fontSize: props.fontSize }}
                          />
                        );
                      case "Archived Discussions":
                        return (
                          <InboxIconTwoTone
                            style={{ fontSize: props.fontSize }}
                          />
                        );
                    }
                  })()}
                </ListItemIcon>
              );
            }
          })()}
          <ListItemText disableTypography primary={props.rightSide ? link.title : link.name} />
        </ListItem>
      ))}

    </div>
  );
}

export default SidebarNavigation;
