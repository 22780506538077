import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import SidebarNavigation from "./SidebarNavigation";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "50px",
    width: "100%",
    backgroundColor: "red",
  },
  item: {
    fontSize: "1.8rem",
  },
}));

function RightSidebarItems(props) {
  const classes = useStyles();
  const [hotDiscussions, setHotDiscussions] = React.useState([]);
  const interests = [
    {name: "Bad actor steals $250,000 from Bisq users after faulty security patch"},
    {name: "Coronavirus live updates: NYC Mayor warns tighter coronavirus restrictions may be needed, Yelp starts layoffs, furloughs - CNBC"},
    // {name: "Coronavirus and shopping for supplies: Getting to the bottom of the toilet paper shortage - USA TODAY"},
    {name: "Update Your iPhone to Solve a Big FaceTime Problem"},
  ];
  const recentPosts = [
    {name: "Bad actor steals $250,000 from Bisq users after faulty security patch"},
  ];

  useEffect(() => {
    const fetchData = async() => {
      const response = await fetch("/api/top_articles");
      const data = await response.json();
      data.rows.map((item) => {
        item.linkPath = `/discussion/${item.article_id}`;
        return item;
      })
      setHotDiscussions(data.rows);
      console.log(data.rows);
      // console.log(newData);
    }
    fetchData();
  }, [])

  return (
    <React.Fragment>
      <Divider />
      <SidebarNavigation
        headingName="Top Discussions"
        headingSize={props.headingSize}
        fontSize={props.fontSize}
        links={hotDiscussions}
        padding={props.padding}
        rightSide
      />
      {/* <Divider />
      <SidebarNavigation
        headingName="Pinned Discussions"
        headingSize={props.headingSize}
        fontSize={props.fontSize}
        fontColor="#ea6f44"
        links={interests}
        padding={props.padding}
        maxHeight={"400px"}
      />
      <Divider />
      <SidebarNavigation
        headingName="Recently Viewed Discussions"
        headingSize={props.headingSize}
        fontSize={props.fontSize}
        links={recentPosts}
        padding={props.padding}
      /> */}
      <Divider />
    </React.Fragment>
  );
}

export default RightSidebarItems;
