import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import SidebarNavigation from "./SidebarNavigation";
import ProfileGlimpseSidebar from "./ProfileGlimpseSidebar";
import { CurrentUserContext } from "../App";

const useStyles = makeStyles((theme) => ({

}));

function LeftSidebarItems(props) {
  const classes = useStyles();
  const currentUser = React.useContext(CurrentUserContext);
  const navLinks = [
    { name: "Home", linkPath: "/" },
    { name: "Profile", linkPath: `/${currentUser.id === "notloggedin" ? "login" : currentUser.screen_name}` },
    { name: "Archived Discussions", linkPath: "/archived" },
  ];
  const keyLinks = [
    { name: "Our Mission", linkPath: "/mission" },
    { name: "Code of Conduct", linkPath: "/codeofconduct" },
    { name: "Privacy Policy", linkPath: "/privacypolicy" },
    { name: "Terms of Service", linkPath: "/termsofservice" },
    { name: "Contact", linkPath: "/contact" },
  ];


  return (
    <React.Fragment>
      {(() => {
        if(props.mobile) {
          return <ProfileGlimpseSidebar />
        }
      })()}
      <Divider />
      <SidebarNavigation
        headingName="Navigation"
        headingSize={props.headingSize}
        fontSize={props.fontSize}
        links={navLinks}
        padding={props.padding}
        icons
      />
      <Divider />
      <SidebarNavigation
        headingName="Key Links"
        headingSize={props.headingSize}
        fontSize={props.fontSize}
        links={keyLinks}
        padding={props.padding}
        
      />
      <Divider />
    </React.Fragment>
  );
}

export default LeftSidebarItems;
