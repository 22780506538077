import React, { useEffect, useState, useCallback } from "react";
import {
  fade,
  makeStyles,
  Typography,
  TextField,
  Card,
  CardContent,
  Button,
  Hidden,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import MobileSidebars from "./MobileSidebars";
import ChatMessage from "./ChatMessage";
import PeopleAltTwoToneIcon from "@material-ui/icons/PeopleAltTwoTone";
import WhatshotTwoToneIcon from "@material-ui/icons/WhatshotTwoTone";
import TrendingDownTwoToneIcon from "@material-ui/icons/TrendingDownTwoTone";
import LaunchIcon from "@material-ui/icons/LaunchTwoTone";
import socketClient from "socket.io-client";
import useChat from "./useChat";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import CurrentUserContext from "../App";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "750px",
    minWidth: "750px",
    minHeight: "calc(100vh - 120px)",
    backgroundColor: fade(theme.palette.background.paper, 0.6),
    borderRadius: "0px",
  },
  cardContent: {
    paddingTop: "0px",
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  messagesContainer: {
    height: "calc(100vh - 337px)",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflowY: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "green",
  },
  chatBox: {},
  inputField: {
    height: "100%",
    fontSize: "1.75rem",
    lineHeight: "105%",
  },
  placeholderText: {
    fontSize: "1.75rem",
  },
  sendButton: {
    float: "right",
    color: theme.palette.primary.main,
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.25)",
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: "0px",
    backgroundColor: "transparent",
    fontSize: "1rem",
  },
  personCount: {
    border: `solid 1px ${theme.palette.secondary.main}`,
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px",
    fontSize: "1rem",
    color: theme.palette.secondary.main,
  },
  timeRemaining: {
    color: theme.palette.text.disabled,
    fontSize: "1.25rem",
    marginLeft: theme.spacing(2),
    display: "inline-block",
  },
  bottomRow: {
    marginTop: theme.spacing(1),
  },
  topBar: {
    height: "85px",
    backgroundColor: "#353535",
    // overflow: "hidden",
  },
  headline: {
    paddingTop: theme.spacing(1.75),
    fontSize: "1.25rem",
    margin: "auto",
    height: "3ch",
    textAlign: "center",
    maxWidth: "85%",
    whiteSpace: "noWrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dialogContent: {
    // marginLeft: "auto",
    textAlign: "center",
  },
}));

// const SERVER = "http://localhost:5000";

function Chatroom(props) {
  const classes = useStyles();
  //   const socket = socketClient(SERVER);
  //   socket.on('connection', () => {
  //     console.log(`I'm connected with the back-end`);
  // });

  const { roomId } = props.match.params; // Gets roomId from URL
  const { messages, sendMessage, setMessages } = useChat(roomId); // Creates a websocket and manages messaging
  const [newMessage, setNewMessage] = useState(""); // Message to be sent
  const [dialogOpen, setDialogOpen] = useState(true);
  const [articleTitle, setArticleTitle] = React.useState("");
  const [article, setArticle] = React.useState();
  const [articleUrl, setArticleUrl] = React.useState("");
  const [articleDateAdded, setArticleDateAdded] = React.useState("");
  const [articleLikes, setArticleLikes] = React.useState("");
  const [articleDislikes, setArticleDislikes] = React.useState("");
  const [numChatters, setNumChatters] = useState("");
  const currentUser = React.useContext(CurrentUserContext);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);
  };
  const messageClear = (e) => {
    e.preventDefault();
    setNewMessage("");
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    sendMessage(newMessage);
    setNewMessage("");
  };


  useEffect(() => {
    const fetchData = async () => {
      console.log(props.match.params.roomId);
      const response = await fetch(
        `/api/article_from_id/${props.match.params.roomId}`
      );
      const data = await response.json();
      console.log(data);
      console.log(data.rows[0].title);
      console.log(data.rows[0].date_added);
      console.log(messages);
      setArticle(data.rows[0]);
      setArticleDateAdded(data.rows[0].date_added);
      setArticleTitle(data.rows[0].title);
      setArticleUrl(data.rows[0].url);
      setArticleLikes(data.rows[0].likes);
      setArticleDislikes(data.rows[0].dislikes);
    };
    fetchData();
  }, [props.match.params.roomId]);
  
  useEffect(() => {
    setMessages([]);
  }, [props.match.params.roomId])

  useEffect(() => {
    const fetchData = async () => {
      console.log(props.match.params.roomId);
      const response = await fetch(`/api/discussions/num_chatters/${props.match.params.roomId}`);
      const data = await response.json();
      console.log("AAAAAAAAAAAAAAAAAAH");
      console.log("NUM CHATTERS ----")
      console.log(data);
      setNumChatters(data);
    };
    fetchData();
  }, [numChatters]);
  

  const postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  const handleLike = useCallback((articleID) => {
    return async (e) => {
    console.log("handle like button")
    console.log(articleID)
    e.preventDefault();
    const formData = {
      articleID: articleID
    }
    postData("/api/articles/add_like", formData);
    }
  }, []);

  const handleDislike = useCallback((articleID) => {
    return async (e) => {
    console.log("handle dislike button")
    console.log(articleID)
    e.preventDefault();
    const formData = {
      articleID: articleID
    }
    postData("/api/articles/add_dislike", formData);
    }
  }, []);

  let timeLeftString;
  let finished = false;
  if (!props.archived) {
    const timeAdded = new Date(articleDateAdded);
    const endTime = timeAdded.getTime() + 86400000;
    const currentTime = new Date().getTime();
    const [timeLeft, setTimeLeft] = useState(new Date(endTime - currentTime));
    timeLeftString = `Time Left: ${timeLeft.toUTCString().substr(17, 8)}`;
    useEffect(() => {
      setTimeout(() => {
        setTimeLeft(new Date(endTime - currentTime));
      }, 1000);
    });
    if (endTime - currentTime <= 0) {
      timeLeftString = "FINISHED";
      finished = true;
    }
  } else {
    timeLeftString = "ARCHIVED";
  }

  return (
    <React.Fragment>
      {/* <Dialog open={dialogOpen}>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Live Discussion is currently undergoing maintenance.
          </DialogContentText>
          <Button onClick={handleDialogClose}>Ok</Button>
        </DialogContent>
      </Dialog> */}
      <Card className={classes.container}>
        {/* Add button with downward arrows spanning across bottom of the top bar
        Button expands the top bar to a good height and then overflows with scroll
        See the rest of the headline and also some body text from the article
        Links to the article
         */}
        <div className={classes.topBar}>
          <Hidden lgUp>
            <MobileSidebars />
          </Hidden>
          <a className={classes.headline} style={{color: "white", textDecoration: "none"}} href={articleUrl} target="_blank" rel="noopener noreferrer">
            <div style={{}}>
              <Typography className={classes.headline}>{articleTitle}</Typography>
              <LaunchIcon />
            </div>
          </a>
        </div>
        <CardContent className={classes.cardContent}>
          <div className={classes.messagesContainer}>
            {messages.map((message) => (
              <ChatMessage
                messageText={message.body}
                userName="Jean Shallet"
                senderId={message.senderId}
                screenName="TOPTIERPLAYER"
                profilePic="https://images.unsplash.com/photo-1519058082700-08a0b56da9b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
                nameColor="firebrick"
                timeStamp={message.time}
                anonymous
              />
            ))}
          </div>
          {(() => {
            if (props.archived) {
              return (
                <TextField
                  className={classes.chatBox}
                  margin="normal"
                  id="createMessage"
                  label="Discussion Concluded"
                  InputLabelProps={{ className: classes.placeholderText }}
                  fullWidth
                  multiline
                  disabled
                  rowsMax="5"
                  inputProps={{ className: classes.inputField }}
                />
              );
            } else {
              return (
                <ValidatorForm onSubmit={messageClear} id="messagebox">
                  <TextValidator
                    className={classes.chatBox}
                    margin="normal"
                    id="createMessage"
                    label="Send a message"
                    InputLabelProps={{ className: classes.placeholderText }}
                    fullWidth
                    multiline
                    rowsMax="5"
                    inputProps={{ className: classes.inputField }}
                    value={newMessage}
                    onChange={handleNewMessageChange}
                    onSubmit={messageClear}
                  />
                </ValidatorForm>
              );
            }
          })()}
          <div className={classes.bottomRow}>
            {(() => {
              if (props.archived) {
                return (
                  <Typography
                    className={classes.timeRemaining}
                    style={{ marginLeft: "0px" }}
                  >
                    Archived
                  </Typography>
                );
              } else {
                return (
                  <React.Fragment>
                    <Button
                      className={classes.personCount}
                      startIcon={
                        <PeopleAltTwoToneIcon style={{ fontSize: "1.5rem" }} />
                      }
                    >
                      {numChatters}
                    </Button>
                    <Typography className={classes.timeRemaining}>
                      {timeLeftString}
                    </Typography>
                    <Button startIcon={<TrendingDownTwoToneIcon />} style={{marginBottom:"5px", marginLeft:"8em"}} onClick={handleDislike(roomId)}>{articleDislikes}</Button>
                    <Button startIcon={<WhatshotTwoToneIcon />} style={{marginBottom:"5px"}} onClick={handleLike(roomId)}>{articleLikes}</Button>
                    <Button
                      className={classes.sendButton}
                      onClick={handleSendMessage}
                      type="submit"
                      form="messagebox"
                    >
                      Send
                    </Button>
                  </React.Fragment>
                );
              }
            })()}
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default Chatroom;
