import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "750px",
    backgroundColor: "transparent",
    margin: "auto",
    paddingBottom: theme.spacing(1.5),
    borderBottom: ".3px solid #909090",
  },
  leftHalfContainer: {
    display: "inline-block",
    textAlign: "center",
    width: "50%",
  },
  rightHalfContainer: {
    display: "inline-block",
    textAlign: "center",
    width: "50%",
  },
  buttonActive: {
    fontSize: "2.6rem",
    width:"350px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)",
    textShadow: "1px 1px rgba(0, 0, 0, 0.25)",
    margin: theme.spacing(1),
    padding: "0px 10px",
    borderRadius: "0px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  activeUnderline: {
    height: "10px",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  buttonInactive: {
    fontSize: "2.6rem",
    width:"350px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)",
    textShadow: "1px 1px rgba(0, 0, 0, 0.25)",
    margin: theme.spacing(1),
    padding: "0px 10px",
    borderRadius: "0px",
    fontWeight: "bold",
    color: "gray",
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

function FormalityContainer(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.leftHalfContainer}>
        {(() => {
          if (props.active) {
            return (
              <React.Fragment>
                <Button className={classes.buttonActive} component={Link} to={"/"}>
                  DISCUSSIONS
                </Button>
                <div className={classes.activeUnderline}></div>
              </React.Fragment>
            );
          } else {
            return (
              <Button className={classes.buttonInactive} component={Link} to={"/"}>
                DISCUSSIONS
              </Button>
            );
          }
        })()}
      </div>
      <div className={classes.rightHalfContainer}>
        {(() => {
          if (props.active) {
            return (
              <Button className={classes.buttonInactive} component={Link} to={"/timeline"}>
                TIMELINE
              </Button>
            );
          } else {
            return (
              <React.Fragment>
                <Button className={classes.buttonActive} component={Link} to={"/timeline"}>
                  TIMELINE
                </Button>
                <div className={classes.activeUnderline}></div>
              </React.Fragment>
            );
          }
        })()}
      </div>
    </div>
  );
}

export default FormalityContainer;
