import React from "react";
import {
  Dialog,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "900px",
  },
  dialogContainer: {
    width: "900px",
    margin: "auto",
  },
  title: {
    padding: "0",
    margin: "auto",
    fontSize: "2.5rem",
  },
  innerContainer: {
    paddingTop: "0",
    paddingBottom: "0",
    margin: theme.spacing(3),
    textAlign: "center",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  text: {
    padding: "0",
    fontSize: "1.5rem",
    width: "85%",
    margin: "auto",
    textAlign: "justify",
  },
  inputField: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
    },
  },
  inputContainer: {
    width: "85%",
    margin: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  bottomButtons: {
    marginBottom: theme.spacing(0.8),
  },
}));

function LoginBox(props) {

  React.useEffect(() => {
    const formData = fields
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== formData.password.value) {
        return false;
      }
      return true;
    })
  })

  const classes = useStyles();

  const [signupOpen, setSignupOpen] = React.useState(false);
  const fieldsPlaceholder = {
    firstName: "",
    lastName: "",
    screenName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const [fields, setFields] = React.useState(fieldsPlaceholder);
  const clearFields = () => {
    setFields(fieldsPlaceholder);
  };

  const postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      credientials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedField = { ...fields[name] };
    updatedField.value = value;

    setFields({
      ...fields,
      [name]: updatedField,
    });
  };
  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: e.target.email.value,
      lastName: e.target.lastName.value,
      screenName: e.target.screenName.value,
      firstName: e.target.firstName.value,
      password: e.target.password.value,
      // confirmPassword: e.target.confirmPassword.value,
    };
    postData("/api/users/register", formData);
    clearFields();
    props.close();
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    postData("/api/users/login", formData);
    clearFields();
    props.close();
  };

  const handleClickSignupOpen = () => {
    setSignupOpen(true);
  };
  const handleSignupClose = () => {
    clearFields();
    setSignupOpen(false);
  };

  return (
    <Dialog
      className={classes.dialogContainer}
      open={props.open}
      onClose={props.close}
      onExited={handleSignupClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"md"}
      style={{ width: "750px" }}
    >
      {(() => {
        if (signupOpen) {
          return (
            <DialogContent className={classes.innerContainer}>
              <DialogTitle className={classes.title} id="form-dialog-title">
                PLEASE SIGN UP
              </DialogTitle>
              <DialogContentText
                className={classes.text}
                style={{ textAlign: "center" }}
              >
                Please enter your login credentials.
              </DialogContentText>
              <DialogContentText
                className={classes.text}
                style={{ fontSize: "1rem", textAlign: "center" }}
              >
                Already have an account?
              </DialogContentText>
              <Button onClick={handleSignupClose} style={{ marginTop: "5px" }}>
                Sign In
              </Button>
              <hr
                style={{
                  width: "45%",
                  borderColor: "gray",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />

              <ValidatorForm onSubmit={handleRegisterSubmit} id="registerForm">
                <TextValidator
                  className={classes.inputContainer}
                  margin="normal"
                  name="firstName"
                  id="firstName"
                  label="First Name"
                  type="text"
                  variant="outlined"
                  autoFocus
                  inputProps={{ className: classes.inputField }}
                  required
                  onChange={handleChange}
                  value={fields.firstName.value}
                  validators={["maxStringLength:100"]}
                  errorMessages={["Name is too long."]}
                />
                <TextValidator
                  className={classes.inputContainer}
                  margin="normal"
                  name="lastName"
                  id="lastName"
                  label="Last Name"
                  type="text"
                  variant="outlined"
                  inputProps={{ className: classes.inputField }}
                  required
                  onChange={handleChange}
                  value={fields.lastName.value}
                  validators={["maxStringLength:100"]}
                  errorMessages={["Name is too long."]}
                />
                <TextValidator
                  className={classes.inputContainer}
                  margin="normal"
                  name="screenName"
                  id="screenName"
                  label="Username"
                  type="text"
                  variant="outlined"
                  inputProps={{ className: classes.inputField }}
                  required
                  onChange={handleChange}
                  value={fields.screenName.value}
                  validators={["maxStringLength:20"]}
                  errorMessages={["Username is too long."]}
                />
                <TextValidator
                  className={classes.inputContainer}
                  margin="normal"
                  name="email"
                  id="email"
                  label="Email Address"
                  type="text"
                  variant="outlined"
                  inputProps={{ className: classes.inputField }}
                  required
                  onChange={handleChange}
                  value={fields.email.value}
                  validators={["isEmail", "maxStringLength: 320"]}
                  errorMessages={["Not a valid email.", "Email is too long"]}
                />
                <TextValidator
                  className={classes.inputContainer}
                  margin="normal"
                  name="password"
                  id="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  onChange={handleChange}
                  value={fields.password.value}
                  inputProps={{ className: classes.inputField }}
                  required
                  validators={["minStringLength: 8"]}
                  errorMessages={["Password must be atleast 8 characters."]}
                />
                <TextValidator
                  className={classes.inputContainer}
                  margin="normal"
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  onChange={handleChange}
                  value={fields.confirmPassword.value}
                  inputProps={{ className: classes.inputField }}
                  required
                  validators={["isPasswordMatch"]}
                  errorMessages={["Passwords do not match."]}
                />
              </ValidatorForm>
              <DialogActions className={classes.bottomButtons}>
                <Button onClick="" type="submit" form="registerForm">
                  Sign Up
                </Button>
                <Button onClick={props.close}>Cancel</Button>
              </DialogActions>
            </DialogContent>
          );
        } else {
          return (
            <DialogContent className={classes.innerContainer}>
              <DialogTitle className={classes.title} id="form-dialog-title">
                Welcome Back!
              </DialogTitle>
              <DialogContentText
                className={classes.text}
                style={{ textAlign: "center" }}
              >
                Please enter your login credentials.
              </DialogContentText>
              <DialogContentText
                className={classes.text}
                style={{ fontSize: "1rem", textAlign: "center" }}
              >
                Don't have an account?
              </DialogContentText>
              <Button
                onClick={handleClickSignupOpen}
                style={{ marginTop: "5px" }}
              >
                Sign Up
              </Button>
              <hr
                style={{
                  width: "45%",
                  borderColor: "gray",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
              <ValidatorForm onSubmit={handleLoginSubmit} id="loginForm">
                <TextValidator
                  className={classes.inputContainer}
                  autoFocus
                  margin="normal"
                  id="email"
                  name="email"
                  label="Email Address"
                  type="email"
                  variant="outlined"
                  inputProps={{ className: classes.inputField }}
                  required
                  onChange={handleChange}
                  value={fields.email.value}
                  validators={["isEmail"]}
                  errorMessages={["Not a valid email."]}
                />
                <TextValidator
                  className={classes.inputContainer}
                  margin="normal"
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  inputProps={{ className: classes.inputField }}
                  required
                  onChange={handleChange}
                  value={fields.password.value}
                />
                <DialogActions className={classes.bottomButtons}>
                  <Button onClick={() => {setTimeout(props.isLoggedIn, 1500); window.location.reload()}} type="submit" form="loginForm">
                    Login
                  </Button>
                  <Button onClick={props.close}>Cancel</Button>
                </DialogActions>
              </ValidatorForm>
            </DialogContent>
          );
        }
      })()}
    </Dialog>
  );
}

export default LoginBox;
