import React, { useEffect, useState } from "react";
import { makeStyles, Button, Menu, MenuItem } from "@material-ui/core";
import FollowersList from "./FollowersList";
import { Switch, Route, Link, useParams } from "react-router-dom";
import Thought from "./Thought";
import CurrentUserContext from "../App";

const useStyles = makeStyles((theme) => ({
  navbar: {
    textAlign: "center",
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    borderBottom: ".3px solid #909090",
  },
  third: {
    display: "inline-block",
    textAlign: "center",
    width: "1/3",
  },
  buttonActive: {
    fontSize: "1.75rem",
    width: "230px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)",
    textShadow: "1px 1px rgba(0, 0, 0, 0.25)",
    margin: theme.spacing(1),
    padding: "0px 10px",
    borderRadius: "0px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  activeUnderline: {
    height: "10px",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  buttonInactive: {
    fontSize: "1.75rem",
    width: "230px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)",
    textShadow: "1px 1px rgba(0, 0, 0, 0.25)",
    margin: theme.spacing(1),
    padding: "0px 10px",
    borderRadius: "0px",
    fontWeight: "bold",
    color: "gray",
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

function ProfileNavbar(props) {
  const classes = useStyles();
  const currentUser = React.useContext(CurrentUserContext);
  const { username } = useParams();
  

  return (
    <div className={classes.navbar}>
      <div className={classes.third}>
        {(() => {
          if (props.activeOne) {
            return (
              <React.Fragment>
                <Button className={classes.buttonActive} component={Link} to={`${username}`}>
                  Thoughts
                </Button>
                <div className={classes.activeUnderline}></div>
              </React.Fragment>
            );
          } else {
            return (
              <Button className={classes.buttonInactive} component={Link} to={`${username}`}>
                Thoughts
              </Button>
            );
          }
        })()}
      </div>
      <div className={classes.third}>
        {(() => {
          if (props.activeTwo) {
            return (
              <React.Fragment>
                <Button className={classes.buttonActive} component={Link} to={`${username}/followers`}>
                  Followers
                </Button>
                <div className={classes.activeUnderline}></div>
              </React.Fragment>
            );
          } else {
            return (
              <Button className={classes.buttonInactive} component={Link} to={`${username}/followers`}>
                Followers
              </Button>
            );
          }
        })()}
      </div>
      <div className={classes.third}>
        {(() => {
          if (props.activeThree) {
            return (
              <React.Fragment>
                <Button className={classes.buttonActive} component={Link} to={`${username}/following`}>
                  Following
                </Button>
                <div className={classes.activeUnderline}></div>
              </React.Fragment>
            );
          } else {
            return (
              <Button className={classes.buttonInactive} component={Link} to={`${username}/following`}>
                Following
              </Button>
            );
          }
        })()}
      </div>
     
    </div>
  );
}

function ProfileContent(props) {
  const classes = useStyles();
  const [thoughts, setThoughts] = useState([]);
  const thoughtsPerPage = 50;
  const [page, setPage] = useState(1);
  const { username } = useParams();
  const [numPages, setNumPages] = React.useState(
    Math.ceil(thoughts.length / thoughtsPerPage)
  );
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    const fetchData = async () => {
      const userIDResponse = await fetch(`/api/users/${username}`);
      const userIDJSON = await userIDResponse.json();
      const userID = userIDJSON.rows[0].id;
      console.log(userID);
      const response = await fetch(`/api/thoughts/get/${userID}`);
      const data = await response.json();
      setThoughts(data.rows);
      console.log("PROFILE PAGE THOUGHT FETCH");
      console.log(data.rows);
      console.log(thoughts);
      setNumPages(Math.ceil(data.rows.length / thoughtsPerPage));
    };
    fetchData();
  }, []);
  return (
    <div>
      {thoughts
        .slice((page - 1) * thoughtsPerPage, page * thoughtsPerPage)
        .map((thought) => (
          <React.Fragment>
            <Thought
              userName={`${thought.first_name} ${thought.last_name}`}
              screenName={thought.screen_name}
              profilePic="https://images.unsplash.com/photo-1519058082700-08a0b56da9b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
              userColor={thought.color === undefined ? "white" : thought.color }
              time={thought.date_posted}
              body={thought.body}
              likes={thought.likes}
              dislikes={thought.dislikes}
              thoughtID={thought.thought_id}
              comments="0"
            />
          </React.Fragment>
        ))}
    </div>
  )
}

function ProfilePage() {
  const classes = useStyles();

  return (
    <div>
      <Switch>
        <Route exact path="/:username">
          <ProfileNavbar activeOne />
          <ProfileContent />
        </Route>
        <Route exact path="/:username/followers">
          <ProfileNavbar activeTwo/>
          <FollowersList />
        </Route>
        <Route exact path="/:username/following">
          <ProfileNavbar activeThree/>
          <FollowersList />
        </Route>
      </Switch>
    </div>
  );
}

export default ProfilePage;
