import React from "react";
import {
  makeStyles,
  Menu,
  MenuItem,
  Avatar,
  Typography,
  ListSubheader,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    // marginRight: "200px"
  },
  notificationContainer: {
    maxWidth: "45ch",
    maxHeight: "400px",
    width: "100%",
    whiteSpace: "normal",
  },
  notificationText: {
    fontSize: "1.25rem",
    display: "inline",
  },
  notificationAvatar: {
    marginRight: theme.spacing(1.5),
  },
  notificationTimestamp: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,
  },
  notificationMenuTitle: {
    position: "relative",
    color: theme.palette.text.disabled,
    lineHeight: "36px",
    fontWeight: "bold",
  },
}));

function NotificationMenu(props) {
  const classes = useStyles();

  return (
    <Menu
      id="notification-menu"
      className={classes.menuContainer}
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.close}
    >
    <ListSubheader className={classes.notificationMenuTitle}>Notifications</ListSubheader>
    <Divider />
      {/* <BasicNotification
        followerPic="https://images.unsplash.com/photo-1508958371115-f0eecf6774c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1347&q=80"
        followerName="John Oliver"
        onClick={props.close}
        type="follow"
      /> */}
    </Menu>
  );
}

function BasicNotification(props) {
  const classes = useStyles();
  let notificationMessage;
  if (props.type === "follow") {
    notificationMessage = "has started following you.";
  } else if (props.type === "thoughtLike") {
    notificationMessage = "liked your thought.";
  } else if (props.type === "commentLike") {
    notificationMessage = "liked your comment.";
  }

  return (
    <MenuItem
      onClick={props.onClick}
      className={classes.notificationContainer}
      divider
    >
      <Avatar src={props.followerPic} className={classes.notificationAvatar} />
      <Typography className={classes.notificationText}>
        {props.followerName} {notificationMessage}
      </Typography>
      <Typography className={classes.notificationTimestamp}>17h ago</Typography>
    </MenuItem>
  );
}

export default NotificationMenu;
