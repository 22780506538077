import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import MainContent from "./components/MainContent";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";

export const CurrentUserContext = React.createContext();

function App() {
  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#EA6F44",
        light: "#353839",
      },
      secondary: {
        main: "#DC143C",
      },
      background: {
        paper: "#2a2d2e",
        default: "#1f1f1f",
      },
    },
  });

  const [currentUser, setCurrentUser] = React.useState({id: "notloggedin"});

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/users/current_user");
        const data = await response.json();
        // setFirstName(data.rows[0].first_name);
        // console.log("LARGEST TEST EVER");
        // console.log(data.rows[0]);
        setCurrentUser(data.rows[0]);
      }
      catch(err) {
        console.log(err);
      }
    }
    fetchData()
  },[])


  return (
    <Router>
      <div className="App" style={{backgroundColor: "#1f1f1f"}}>
        <ThemeProvider theme={theme}>
          <CurrentUserContext.Provider value={currentUser}>
            <Navbar></Navbar>
            <MainContent></MainContent>
          </CurrentUserContext.Provider>
        </ThemeProvider>
      </div>
    </Router>
  );
}

export default App;
