import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    marginTop: theme.spacing(1.2),
    textAlign: "center",
    width: "750px",
  },
  button: {
    fontSize: "1rem",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)",
    textShadow: "1px 1px rgba(0, 0, 0, 0.25)",
    margin: "7px",
    padding: "0px 10px",
    borderRadius: "0px",
    fontWeight: "bold",
    color: theme.palette.common.business, 
    border: `1px solid ${theme.palette.common.business}`,
  },
}));

function FormalCategories() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        style={{ color: "thistle", border: "1px solid thistle" }}
      >
        business
      </Button>
      <Button
        className={classes.button}
        style={{ color: "firebrick", border: "1px solid firebrick" }}
      >
        health
      </Button>
      <Button
        className={classes.button}
        style={{ color: "lightseagreen", border: "1px solid lightseagreen" }}
      >
        science
      </Button>
      <Button
        className={classes.button}
        style={{ color: "powderblue", border: "1px solid powderblue" }}
      >
        sports
      </Button>
      <Button
        className={classes.button}
        style={{ color: "royalblue", border: "1px solid royalblue" }}
      >
        technology
      </Button>
      <Button
        className={classes.button}
        style={{ color: "slateblue", border: "1px solid slateblue" }}
      >
        general
      </Button>
    </div>
  );
}

export default FormalCategories;
