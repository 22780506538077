import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "3rem",
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginBottom: theme.spacing(2.5),
  },
  body: {
    fontSize: "1.25rem",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  homeButton: {
    fontSize: "2rem",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)",
    textShadow: "1px 1px rgba(0, 0, 0, 0.25)",
    padding: "0px 10px",
    borderRadius: "0px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textAlign: "center",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  container: {
    textAlign: "center"
  }
}));

function pageNotFound() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>404 PAGE NOT FOUND</Typography>
      <Button component={Link} to={"/"} className={classes.homeButton}>Return Home</Button>
    </div>
  );
}

export default pageNotFound;
