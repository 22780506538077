import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import SecondaryPageTitle from "./SecondaryPageTitle";

const useStyles = makeStyles((theme) => ({
  inputField: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
    },
  },
  inputContainer: {
    width: "85%",
    margin: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  heading: {
    fontSize: "2rem",
    color: theme.palette.text.secondary,
  },
}));

function LoginPage() {
  const classes = useStyles();

  const postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      credientials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    postData("/api/users/login", formData);
  };

  const fieldsPlaceholder = {
    email: "",
    password: "",
  };
  const [fields, setFields] = React.useState(fieldsPlaceholder);
  const clearFields = () => {
    setFields(fieldsPlaceholder);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedField = { ...fields[name] };
    updatedField.value = value;

    setFields({
      ...fields,
      [name]: updatedField,
    });
  };

  return (
    <div>
      <ValidatorForm onSubmit={handleLoginSubmit} id="loginForm">
        <TextValidator
          className={classes.inputContainer}
          autoFocus
          margin="normal"
          id="email"
          name="email"
          label="Email Address"
          type="email"
          variant="outlined"
          inputProps={{ className: classes.inputField }}
          required
          onChange={handleChange}
          value={fields.email.value}
          validators={["isEmail"]}
          errorMessages={["Not a valid email."]}
        />
        <TextValidator
          className={classes.inputContainer}
          margin="normal"
          id="password"
          name="password"
          label="Password"
          type="password"
          variant="outlined"
          inputProps={{ className: classes.inputField }}
          required
          onChange={handleChange}
          value={fields.password.value}
        />
      </ValidatorForm>
      <Button onClick={() => {window.location.reload()}} type="submit" form="loginForm">
        Login
      </Button>
    </div>
  );
}

export default LoginPage;