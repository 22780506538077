import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuOpenTwoToneIcon from "@material-ui/icons/MenuOpenTwoTone";
import { SwipeableDrawer } from "@material-ui/core";
import List from "@material-ui/core/List";
import RightSidebarItems from "./RightSidebarItems";
import LeftSidebarItems from "./LeftSidebarItems";

const useStyles = makeStyles((theme) => ({
  sidebarButtonContainer: {
    display: "flex",
    position: "absolute",
    width: "750px",
    margin: "auto",
  },
  leftSidebarButton: {
    transform: "scale(-1, -1)",
    marginRight: "auto",
    marginTop: "12px",
    marginLeft: "12px",
  },
  rightSidebarButton: {
    marginLeft: "auto",
    marginTop: "12px",
    marginRight: "12px",
  },
  icon: {
    fontSize: "2rem",
  },
  list: {
    width: "500px",
    height: "100%",
    backgroundColor: fade(theme.palette.background.paper, 0.6),
  },
}));

function MobileSidebars() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  return (
    <div className={classes.sidebarButtonContainer}>
      <IconButton
        onClick={() => {
          setState({ ...state, ["left"]: true });
        }}
        size="small"
        className={classes.leftSidebarButton}
      >
        <MenuOpenTwoToneIcon className={classes.icon} />
      </IconButton>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <List className={classes.list} onClick={toggleDrawer("left", false)}>
          <LeftSidebarItems
            headingSize="1.5rem"
            fontSize="2.2rem"
            padding="25px"
            mobile
          />
        </List>
      </SwipeableDrawer>
      <IconButton
        size="small"
        className={classes.rightSidebarButton}
        onClick={() => {
          setState({ ...state, ["right"]: true });
        }}
      >
        <MenuOpenTwoToneIcon className={classes.icon} />
      </IconButton>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <List className={classes.list} onClick={toggleDrawer("right", false)}>
          <RightSidebarItems
            headingSize="1.5rem"
            fontSize="2.2rem"
            padding="25px"
          />
        </List>
      </SwipeableDrawer>
    </div>
  );
}

export default MobileSidebars;
