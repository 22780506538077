import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { Typography, Link, Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import WhatshotTwoToneIcon from "@material-ui/icons/WhatshotTwoTone";
import TrendingDownTwoToneIcon from "@material-ui/icons/TrendingDownTwoTone";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import PeopleAltTwoToneIcon from "@material-ui/icons/PeopleAltTwoTone";
import { Link as RouterLink} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  post: {
    height: "100%",
    width: "100%",
    marginTop: theme.spacing(2),
    margin: "auto",
    backgroundColor: fade(theme.palette.background.paper, 0.6),
    "&:hover": {
      backgroundColor: fade(theme.palette.background.paper, 0.6),
    },
    borderRadius: "0px",
    boxShadow: "2px 8px 11px -1px rgba(0,0,0,0.43)",
    color: theme.palette.text.primary,
  },
  postContent: {
    padding: "0px",
  },
  postImage: {
    height: "250px",
  },
  postLink: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  imageOverlay: {
    position: "absolute",
    backgroundColor: "#1b1d1e",
    opacity: "45%",
    height: "250px",
    width: "100%",
  },
  title: {
    textAlign: "justify",
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  container: {
    textAlign: "center",
    paddingBottom: "10px",
  },
  button: {
    fontSize: "1.6rem",
    backgroundColor: "transparent",
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: "0px",
    color: theme.palette.primary.main,
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.25)",
    padding: "1px 15px",
    margin: "12px",
  },
  voteButton: {
    display: "inline-block",
    color: theme.palette.text.disabled,
    fontSize: "3rem",
  },
  source: {
    display: "inline-block",
    float: "right",
    fontSize: "1rem",
    textAlign: "right",
    marginLeft: "auto",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "8px",
    borderRight: ".3px solid #909090",
    color: theme.palette.text.disabled,
  },
  category: {
    display: "inline-block",
    float: "right",
    fontSize: "1rem",
    textAlign: "right",
    paddingTop: "8px",
    paddingLeft: "10px",
    paddingRight: theme.spacing(4),
    textTransform: "uppercase",
    fontWeight: "bold",
    textShadow: "1.5px 1.5px #141414",
    color: theme.palette.text.disabled,
  },
  time: {
    display: "inline-block",
    fontSize: "1.2rem",
    textAlign: "left",
    paddingLeft: theme.spacing(4),
    paddingRight: "20px",
    paddingTop: "8px",
    color: theme.palette.text.disabled,
  },
  details: {
    width: "750px",
  },
  dislikeButton: {
    display: "inline-block",
    color: theme.palette.text.disabled,
    fontSize: "3rem",
    transform: "rotate(10deg)",
  },
  icon: {
    boxShadow: "0 5px 5px rgba(0, 0, 0, 0.25)",
    marginLeft: "10px",
    border: `solid 1px ${theme.palette.secondary.main}`,
  },
  likeCount: {
    display: "inline-block",
    color: theme.palette.text.secondary,
    fontFamily: "Kanit",
    fontSize: "1.4rem",
    textAlign: "left",
    width: "40px",
    marginRight: "9px",
    marginTop: "20px",
    paddingLeft: "10px",
  },
  personIcon: {
    display: "inline-block",
    position: "absolute",
    fontSize: "1.4rem",
    color: theme.palette.secondary.main,
    marginTop: "11px",
  },
  personCount: {
    display: "inline-block",
    fontSize: "1.2rem",
    marginLeft: "27px",
    color: theme.palette.text.secondary,
  },
  hidden: {
    display: "none"
  },
  dialogContainer: {
    width: "900px",
    margin: "auto",
  },
  innerDialogContainer: {
    paddingTop: "0",
    paddingBottom: "0",
    margin: theme.spacing(3),
    textAlign: "center",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  dialogTitle: {
    padding: "0",
    paddingBottom: "25px",
    margin: "auto",
    fontSize: "1.5rem",
  },
  bottomButtons: {
    marginBottom: theme.spacing(0.8),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function FormalPost(props) {
  const classes = useStyles();
  let timeLeftString;
  let finished = false;
  if (!props.archived) {
    const timeAdded = new Date(props.timeAdded);
    const endTime = timeAdded.getTime() + 86400000;
    const currentTime = new Date().getTime();
    const [timeLeft, setTimeLeft] = useState(new Date(endTime - currentTime));
    timeLeftString = `Time Left: ${timeLeft.toUTCString().substr(17, 8)}`;
    useEffect(() => {
      setTimeout(() => {
        setTimeLeft(new Date(endTime - currentTime));
      }, 1000);
    });
    if (endTime - currentTime <= 0) {
      timeLeftString = "FINISHED";
      finished = true;
    }
  } else {
    timeLeftString = "ARCHIVED";
  }

  const [numLikes, setNumLikes] = useState(Math.floor(Math.random() * 1000));
  const [numDislikes, setNumDisikes] = useState(Math.floor(Math.random() * 400));
  const [numChatters, setNumChatters] = useState(Math.floor(Math.random() * numLikes))
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const handleClickShareDialogOpen = () => {
    setShareDialogOpen(true);
  }

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  }

  const postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };

  const handleLike = useCallback((articleID) => {
    return async (e) => {
    console.log("handle like button")
    console.log(articleID)
    e.preventDefault();
    const formData = {
      articleID: articleID
    }
    postData("/api/articles/add_like", formData);
    }
  }, [])

  const handleDislike = useCallback((articleID) => {
    return async (e) => {
    console.log("handle dislike button")
    console.log(articleID)
    e.preventDefault();
    const formData = {
      articleID: articleID
    }
    postData("/api/articles/add_dislike", formData);
    }
  }, [])

  return (
    <Card className={classes.post}>
      <CardActionArea
        component={Link}
        color="inherit"
        href={props.url}
        target="_blank"
        rel="noopener"
        className={classes.postLink}
      >
        {(function () {
          if (props.image) {
            return (
              <React.Fragment>
                <div className={classes.imageOverlay}></div>
                <CardMedia
                  className={classes.postImage}
                  image={props.image}
                ></CardMedia>
              </React.Fragment>
            );
          }
        })()}
        <CardContent className={classes.postContent}>
          <Typography variant="h6" className={classes.time}>
            {timeLeftString}
          </Typography>
          {(() => {
            if (!props.archived && !finished) {
              return (
                <React.Fragment>
                  <PeopleAltTwoToneIcon className={classes.personIcon} />
                  <Typography variant="h6" className={classes.personCount}>
                    {numChatters}
                  </Typography>
                </React.Fragment>
              );
            }
          })()}
          <Typography
            variant="h6"
            className={classes.category}
            style={{ color: props.categoryColor }}
          >
            Published: {props.publishTime.substr(0, 10)}
          </Typography>
          <Typography variant="h6" className={classes.source}>
            {props.source}
          </Typography>
          <Typography variant="h4" className={classes.title}>
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <div className={classes.container}>
        <Button className={classes.button} disabled={props.archived? true : false} component={RouterLink} to={`/discussion/${props.articleID}`}>Live Discussion</Button>
        <Button className={classes.button} disabled={props.archived? true : false} onClick={handleClickShareDialogOpen}>Share</Button>
        <Dialog className={classes.dialogContainer} open={shareDialogOpen} onClose={handleShareDialogClose} onExited={handleShareDialogClose}>
          <DialogContent className={classes.innerDialogContainer}>
            <DialogContentText className={classes.dialogTitle}>
              Come join the discussion at NewsFlash! {props.title} — https://newsflash.page/discussion/{props.articleID}
            </DialogContentText>
            <Button className={classes.bottomButtons}>Copy</Button>
            <Button className={classes.bottomButtons} onClick={handleShareDialogClose}>Close</Button>
          </DialogContent>
        </Dialog>
        <IconButton size="small" className={classes.icon} disabled={props.archived? true : false} onClick={handleDislike(props.articleID)}>
          <TrendingDownTwoToneIcon className={classes.dislikeButton} />
        </IconButton>
        <Typography className={classes.likeCount}>{props.dislikes}</Typography>
        <IconButton size="small" className={classes.icon} disabled={props.archived? true : false} onClick={handleLike(props.articleID)}>
          <WhatshotTwoToneIcon className={classes.voteButton} />
        </IconButton>
        <Typography className={classes.likeCount}>{props.likes}</Typography>
      </div>
    </Card>
  );
}

export default FormalPost;
