import React from "react";
import { makeStyles, Typography, List, ListItem } from "@material-ui/core";
import SecondaryPageTitle from "./SecondaryPageTitle";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "3rem",
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginBottom: theme.spacing(2.5),
  },
  heading: {
    fontSize: "2rem",
    color: theme.palette.text.secondary,
  },
  body: {
    fontSize: "1.25rem",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function CodeOfConduct() {
  const classes = useStyles();

  return (
    <div>
      <SecondaryPageTitle 
      title="Code of Conduct"
      />
      <Typography className={classes.body}>
        All participants of the NewsFlash Community are expected to abide by our Code
        of Conduct, both online and during in-person events that are hosted
        and/or associated with NewsFlash.
      </Typography>

      <Typography className={classes.heading}>Our Pledge</Typography>

      <Typography className={classes.body}>
        Our goal is to create a community that allows open communication and
        discourse without judgment or hatred. In order to facilitate such an
        environment, we have adopted the following code of conduct. Every
        individual that participates in discussion or uses our service in any
        other way is expected to abide by this code.
      </Typography>

      <Typography className={classes.body}>
        In the interest of fostering an open and welcoming environment, we as
        moderators of NewsFlash pledge to make participation in our project and our
        community a harassment-free experience for everyone, regardless of age,
        body size, disability, ethnicity, gender identity and expression, level
        of experience, nationality, personal appearance, race, religion, or
        sexual identity and orientation.
      </Typography>

      <Typography className={classes.heading}>Our Standards</Typography>

      <Typography className={classes.body}>
        Examples of behavior that contributes to creating a positive environment
        include:
      </Typography>

      <List className={classes.body}>
        <ListItem>Using welcoming and inclusive language</ListItem>
        <ListItem>
          Being respectful of differing viewpoints and experiences
        </ListItem>
        <ListItem>
          Referring to people by their preferred pronouns and using
          gender-neutral pronouns when uncertain
        </ListItem>
        <ListItem>Gracefully accepting constructive criticism</ListItem>
        <ListItem>Focusing on what is best for the community</ListItem>
        <ListItem>Showing empathy towards other community members</ListItem>
      </List>
      <Typography className={classes.body}>
        Examples of unacceptable behavior by participants include:
      </Typography>

      <List className={classes.body}>
        <ListItem>
          The use of sexualized language or imagery and unwelcome sexual
          attention or advances
        </ListItem>
        <ListItem>
          Trolling, insulting/derogatory comments, and personal or political
          attacks
        </ListItem>
        <ListItem>Public or private harassment</ListItem>
        <ListItem>
          Publishing others' private information, such as a physical or
          electronic address, without explicit permission
        </ListItem>
        <ListItem>
          Other conduct which could reasonably be considered inappropriate in a
          professional setting
        </ListItem>
        <ListItem>Dismissing or attacking inclusion-oriented requests</ListItem>
      </List>

      <Typography className={classes.body}>
        We pledge to prioritize marginalized people’s safety over privileged
        people’s comfort. We will not act on complaints regarding:
      </Typography>

      <List className={classes.body}>
        <ListItem>
          ‘Reverse’ -isms, including ‘reverse racism,’ ‘reverse sexism,’ and
          ‘cisphobia’
        </ListItem>
        <ListItem>
          Reasonable communication of boundaries, such as 'leave me alone,' 'go
          away,' or 'I’m not discussing this with you.'
        </ListItem>
        <ListItem>
          Someone’s refusal to explain or debate social justice concepts
        </ListItem>
        <ListItem>
          Criticisms of racist, sexist, cissexist, or otherwise oppressive
          behavior or assumptions
        </ListItem>
        <ListItem>Enforcement</ListItem>
        <ListItem>
          Violations of the Code of Conduct may be reported by contacting the
          team via the abuse report form or by sending an email to contact@newsflash.page
          All reports will be reviewed and investigated and will result in a
          response that is deemed necessary and appropriate to the
          circumstances. Further details of specific enforcement policies may be
          posted separately.
        </ListItem>
      </List>

      <Typography className={classes.body}>
        Moderators have the right and responsibility to remove comments or other
        contributions that are not aligned to this Code of Conduct, or to
        suspend temporarily or permanently any members for other behaviors that
        they deem inappropriate, threatening, offensive, or harmful.
      </Typography>
    </div>
  );
}

export default CodeOfConduct;
