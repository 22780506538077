import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import SecondaryPageTitle from "./SecondaryPageTitle";

const useStyles = makeStyles((theme) => ({
  inputField: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
    },
  },
  inputContainer: {
    width: "85%",
    margin: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  heading: {
    fontSize: "2rem",
    color: theme.palette.text.secondary,
  },
}));

function SettingsPage() {
  const classes = useStyles();

  const fieldsPlaceholder = {
    firstName: "",
    lastName: "",
    screenName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const [fields, setFields] = React.useState(fieldsPlaceholder);
  const clearFields = () => {
    setFields(fieldsPlaceholder);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedField = { ...fields[name] };
    updatedField.value = value;

    setFields({
      ...fields,
      [name]: updatedField,
    });
  };

  return (
    <div>
    <SecondaryPageTitle 
      title="Settings"
    />
      <ValidatorForm id="editProfileForm">
        <TextValidator
          className={classes.inputContainer}
          margin="normal"
          name="firstName"
          id="firstName"
          label="First Name"
          type="text"
          variant="outlined"
          autoFocus
          inputProps={{ className: classes.inputField }}
          required
          onChange={handleChange}
          value={fields.firstName.value}
          validators={["maxStringLength:100"]}
          errorMessages={["Name is too long."]}
        />
        <TextValidator
          className={classes.inputContainer}
          margin="normal"
          name="lastName"
          id="lastName"
          label="Last Name"
          type="text"
          variant="outlined"
          inputProps={{ className: classes.inputField }}
          required
          onChange={handleChange}
          value={fields.lastName.value}
          validators={["maxStringLength:100"]}
          errorMessages={["Name is too long."]}
        />
        <TextValidator
          className={classes.inputContainer}
          margin="normal"
          name="screenName"
          id="screenName"
          label="Username"
          type="text"
          variant="outlined"
          inputProps={{ className: classes.inputField }}
          required
          onChange={handleChange}
          value={fields.screenName.value}
          validators={["maxStringLength:20"]}
          errorMessages={["Username is too long."]}
        />
        <TextValidator
          className={classes.inputContainer}
          margin="normal"
          name="password"
          id="password"
          label="Current Password"
          type="password"
          variant="outlined"
          onChange={handleChange}
          value={fields.password.value}
          inputProps={{ className: classes.inputField }}
          required
          validators={["minStringLength: 8"]}
          errorMessages={["Password must be atleast 8 characters."]}
        />
        <TextValidator
          className={classes.inputContainer}
          margin="normal"
          name="password"
          id="password"
          label="New Password"
          type="password"
          variant="outlined"
          onChange={handleChange}
          value={fields.password.value}
          inputProps={{ className: classes.inputField }}
          required
          validators={["minStringLength: 8"]}
          errorMessages={["Password must be atleast 8 characters."]}
        />
        <TextValidator
          className={classes.inputContainer}
          margin="normal"
          name="confirmPassword"
          id="confirmPassword"
          label="Confirm New Password"
          type="password"
          variant="outlined"
          onChange={handleChange}
          value={fields.confirmPassword.value}
          inputProps={{ className: classes.inputField }}
          required
          validators={["isPasswordMatch"]}
          errorMessages={["Passwords do not match."]}
        />
      </ValidatorForm>
    </div>
  );
}
export default SettingsPage;
