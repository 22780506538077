import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormalPost from "./FormalPost";
import FormalCategories from "./FormalCategories";
import Pagination from "@material-ui/lab/Pagination";
import SettingsPage from "./SettingsPage";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "750px",
  },
  pagination: {
    margin: "auto",
    width:"418px",
    marginTop: theme.spacing(1.5),
  },
}));

function FormalPosts() {
  const classes = useStyles();
  const articlesPerPage = 25;
  const [articles, setArticles] = useState([]);
  const [page, setPage] = React.useState(1);
  const [numPages, setNumPages] = React.useState(
    Math.ceil(articles.length / articlesPerPage)
  );
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/active_articles");
      const data = await response.json();
      setArticles(data.rows);
      setNumPages(Math.ceil(data.rows.length / articlesPerPage));
    };
    fetchData();
  }, {});

  const fetchArticleLikes = async () => {
    const response = await fetch("/api/articles/get_likes");
    const data = await response.json();
    console.log(data);
  };



  return (
    <div className={classes.container}>
      {/* <FormalCategories /> */}
      <div className={classes.pagination}>
      <Pagination
          count={numPages}
          page={page}
          onChange={handlePageChange}
          defaultPage={1}
          showFirstButton
          showLastButton
          classes={{ ul: classes.pagination }}
        />
      </div>
      {articles
        .slice((page - 1) * articlesPerPage, page * articlesPerPage)
        .map((article) => (
          <FormalPost
            title={article.title}
            source={article.source}
            image={article.image_url}
            likes={parseInt(article.likes + Math.floor(Math.random() * 500))}
            dislikes={parseInt(article.dislikes + Math.floor(Math.random() * 81))}
            personCount="0"
            publishTime={article.published_at}
            timeAdded={article.date_added}
            url={article.url}
            articleID={article.article_id}
          />
        ))}
      <div className={classes.pagination}>
      <Pagination
          count={numPages}
          page={page}
          onChange={handlePageChange}
          defaultPage={1}
          showFirstButton
          showLastButton
          classes={{ ul: classes.pagination }}
        />
      </div>
    </div>
  );
}

export default FormalPosts;
