import React from "react";
import { makeStyles } from "@material-ui/core";
import ProfileGlimpseFollower from "./ProfileGlimpseFollower";

const useStyles = makeStyles((theme) => ({

}))

function FollowersList() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ProfileGlimpseFollower 
        userName="Sundar Patel"
        screenName="TOPTIERPLAYER"
        followsYou
      />
      <ProfileGlimpseFollower 
        userName="Sundar Patel"
        screenName="TOPTIERPLAYER"
        following
      />
      <ProfileGlimpseFollower 
        userName="Sundar Patel"
        screenName="TOPTIERPLAYER"
      />
      <ProfileGlimpseFollower 
        userName="Sundar Patel"
        screenName="TOPTIERPLAYER"
        following
        followsYou
      />
      <ProfileGlimpseFollower 
        userName="Sundar Patel"
        screenName="TOPTIERPLAYER"
        following
      />
      <ProfileGlimpseFollower 
        userName="Sundar Patel"
        screenName="TOPTIERPLAYER"
        followsYou
      />
      <ProfileGlimpseFollower 
        userName="Sundar Patel"
        screenName="TOPTIERPLAYER"
      />
    </React.Fragment>
  )
}

export default FollowersList;