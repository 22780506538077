import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LeftSidebarItems from "./LeftSidebarItems";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    position: "relative",
    marginLeft: "50",
    maxWidth: "280px",
    minWidth: "250px",
    width: "100%",
    height: "100%",
    float: "left",
  },
}));

function LeftSidebarDesktop() {
  const classes = useStyles();

  return (
    <div className={classes.sidebar}>
      <LeftSidebarItems headingSize=".9rem"/>
    </div>
  );
}

export default LeftSidebarDesktop;