import React from "react";
import { makeStyles, Button, InputBase, fade } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonActive: {
    fontSize: "1.75rem",
    width: "400px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)",
    textShadow: "1px 1px rgba(0, 0, 0, 0.25)",
    margin: theme.spacing(1),
    padding: "0px 10px",
    borderRadius: "0px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  activeUnderline: {
    height: "10px",
    width: "420px",
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(0.5),
  },
  title: {
    display: "flex",
    borderBottom: ".3px solid #909090",
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  search: {
    position: "relative",
    width: "40%",
    height: "38px",
    minWidth: "200px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
      border: `1px solid ${theme.palette.common.white}`,
    },
    "&.Mui-focused fieldset": {
      backgroundColor: "#800000",
    },
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  input: {
    width: "100%",
    padding: "3px",
    paddingLeft: "6px",
    paddingRight: "6px",
  },
}));

function SecondaryPageTitle(props) {
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <div>
        <Button className={classes.buttonActive}>{props.title}</Button>
        <div className={classes.activeUnderline}></div>
      </div>
      {(() => {
        if (props.search) {
          return (
            <div className={classes.search}>
              <InputBase
                className={classes.input}
                placeholder={props.searchPlaceholder}
              />
            </div>
          );
        }
      })()}
    </div>
  );
}

export default SecondaryPageTitle;
