import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

const NEW_CHAT_MESSAGE_EVENT = "newChatMessage";
const SOCKET_SERVER_URL = "newsflashlive.com:5000";

const useChat = (roomId) => {
  const [messages, setMessages] = useState([]);
  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      transports: ["websocket", "polling"],
      query: { roomId },
      upgrade: true,
    });

    socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, (message) => {
      console.log(`message: ${message}`);
      const incomingMessage = {
        ...message,
        ownedByCurrentUser: message.senderId === socketRef.current.id,
      };
      console.log(`incoming message: ${incomingMessage}`);
      console.log("usechat hook test");
      setMessages((messages) => [...messages, incomingMessage]);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId, messages]);

  const sendMessage = (messageBody) => {
    console.log(`sending message: ${messageBody}`);
    socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
      body: messageBody,
      senderId: socketRef.current.id,
      time: new Date().toLocaleTimeString(),
    });
  };
  // console.log(`messages: ${messages}`)
  return { messages, sendMessage, setMessages };
};

export default useChat;
