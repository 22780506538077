import React from "react";
import { makeStyles, Card, Typography, Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  userContainer: {
    display: "flex",
    width: "100%",
  },
  userName: {
    marginLeft: theme.spacing(1),
    fontSize: "1.5rem",
  },
  screenName: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: ".75rem",
  },
  profilePic: {
    height: theme.spacing(3.6),
    width: theme.spacing(3.6),
    marginTop: theme.spacing(.4),
    // border: `.5px solid ${theme.palette.secondary.main}`,
    // marginRight: theme.spacing(1.5),
  },
  timeStamp: {
    color: theme.palette.text.secondary,
    fontSize: ".75rem",
    marginTop: theme.spacing(1),
    marginLeft: "auto",
  },
  messageText: {
    fontSize: "1.25rem",
  },
}));

function ChatMessage(props) {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <div className={classes.userContainer}>
        <Avatar
          className={classes.profilePic}
          src={props.anonymous ? "https://images.unsplash.com/photo-1523780329224-c3be49f59edd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" : props.profilePic}
          style={{ border: `.4px solid ${props.anonymous ? "#EA6F44" : props.nameColor}` }}
        />
        <Typography
          className={classes.userName}
          style={{ color: props.anonymous ? "#EA6F44" : props.nameColor }}
        >
          {props.anonymous ? "Anonymous" : props.userName }
        </Typography>
        <Typography className={classes.screenName}>
          @{props.anonymous ? props.senderId : props.screenName}
        </Typography>
        <Typography className={classes.timeStamp}>{props.timeStamp}</Typography>
      </div>
      <Typography className={classes.messageText}>{props.messageText}</Typography>
    </Card>
  );
}

export default ChatMessage;
