import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormalPost from "./FormalPost";
import FormalCategories from "./FormalCategories";
import SecondaryPageTitle from "./SecondaryPageTitle";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "750px",
  },
  pagination: {
    margin: "auto",
    width:"418px",
    marginTop: theme.spacing(1.5),
  },
}));

function ArchivedDiscussions() {
  const classes = useStyles();
  const articlesPerPage = 25;
  const [articles, setArticles] = useState([]);
  const [page, setPage] = React.useState(1);
  const [numPages, setNumPages] = React.useState(
    Math.ceil(articles.length / articlesPerPage)
  );
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/archived_articles");
      const data = await response.json();
      setArticles(data.rows);
      setNumPages(Math.ceil(data.rows.length / articlesPerPage));
    };
    fetchData();
  }, {});
  return (
    <div className={classes.container}>
      <SecondaryPageTitle
        title="Archived Discussions"
        search
        searchPlaceholder="Search Archived Discussions…"
      />
      <div className={classes.container}>
        {/* <FormalCategories /> */}
        <div className={classes.pagination}>
          <Pagination
            count={numPages}
            page={page}
            onChange={handlePageChange}
            defaultPage={1}
            showFirstButton
            showLastButton
            classes={{ ul: classes.pagination }}
          />
        </div>
        {/* {console.log(articles.length)} */}
        {articles
          .slice((page - 1) * articlesPerPage, page * articlesPerPage)
          .map((article) => (
            <FormalPost
              title={article.title}
              source={article.source}
              image={article.image_url}
              likes="0"
              dislikes="0"
              personCount="0"
              publishTime={article.published_at}
              url={article.url}
              archived
            />
          ))}
        <div className={classes.pagination}>
          <Pagination
            count={numPages}
            page={page}
            onChange={handlePageChange}
            defaultPage={1}
            showFirstButton
            showLastButton
            classes={{ ul: classes.pagination }}
          />
        </div>
      </div>
    </div>
  );
}

export default ArchivedDiscussions;
