import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import NotificationsNoneTwoToneIcon from "@material-ui/icons/NotificationsNoneTwoTone";
import MailTwoToneIcon from "@material-ui/icons/MailTwoTone";
import LoginBox from "./LoginBox";
import { Link } from "react-router-dom";
import NotificationMenu from "./NotificationMenu.js";
import { useForceUpdate } from "./helperFunctions";
import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    width: "35%",
    minWidth: "200px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
      border: `1px solid ${theme.palette.common.white}`,
    },
    "&.Mui-focused fieldset": {
      backgroundColor: "#800000",
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  appBar: {
    backgroundColor: "#2a2d2e",
  },
  input: {
    width: "100%",
    padding: "3px",
    paddingLeft: "6px",
    paddingRight: "6px",
  },
  toolbar: {
    margin: "auto",
    maxWidth: "1650px",
    minWidth: "750px",
    width: "100%",
  },
  navButtonContainer: {
    marginLeft: "auto",
    paddingRight: "45px",
  },
  navButton: {
    fontSize: "2.3rem",
    padding: "7px",
    margin: "0px",
  },
  logo: {
    textTransform: "lowercase",
    margin: "0px 0px",
    padding: "0px 10px",
    [theme.breakpoints.up("xl")]: {
      marginLeft: "53px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
    textShadow: "0 5px 5px rgba(0, 0, 0, 0.25)",
    fontSize: "1.7rem",
    fontFamily: "Shrikhand",
  },

  dialogContainer: {
    width: "900px",
    margin: "auto",
  },
  innerDialogContainer: {
    paddingTop: "0",
    paddingBottom: "0",
    margin: theme.spacing(3),
    textAlign: "center",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  dialogTitle: {
    padding: "0",
    paddingBottom: "25px",
    margin: "auto",
    fontSize: "2.5rem",
  },
  bottomButtons: {
    marginBottom: theme.spacing(0.8),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function Navbar() {
  const classes = useStyles();
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
  const [notifAnchorEl, setNotifAnchorEl] = React.useState(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const checkIfAuthenticated = async () => {
    const response = await (await fetch("/api/login/authenticate")).json();
    console.log("authentication test");
    console.log(response);
    if (response.authenticated === "true") {
      setIsAuthenticated(true);
    } else if (response.authenticated === "false") {
      setIsAuthenticated(false);
    }
  };
  useEffect(() => {
    checkIfAuthenticated();
  }, {});

  const handleClickLoginOpen = () => {
    setLoginOpen(true);
  };
  const handleLoginClose = () => {
    setLoginOpen(false);
  };
  const handleNotifOpen = (event) => {
    setNotifAnchorEl(event.currentTarget);
  };
  const handleNotifClose = () => {
    setNotifAnchorEl(null);
  };

  const handleClickLogoutDialogOpen = () => {
    setLogoutDialogOpen(true);
  };
  const handleLogoutClose = () => {
    setLogoutDialogOpen(false);
  };

  const handleClickLogout = async () => {
    await fetch("/api/login/logout");
    setTimeout(checkIfAuthenticated(), 1500);
    handleLogoutClose();
    window.location.reload();
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title> NewsFlash </title>
        <meta name="viewport" content="width=750" />
      </MetaTags>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Button
            component={Link}
            to={"/"}
            size="small"
            disableRipple
            className={classes.logo}
          >
            NewsFlash
          </Button>
          <div className={classes.search}>
            <InputBase className={classes.input} placeholder="Search…" />
          </div>
          <div className={classes.navButtonContainer}>
            {(() => {
              if (isAuthenticated) {
                return (
                  <React.Fragment>
                    <Button
                      color="inherit"
                      style={{ fontSize: "1.1rem" }}
                      onClick={handleClickLogoutDialogOpen}
                    >
                      logout
                    </Button>
                    <IconButton size="small" component={Link} to={"/messages"}>
                      <MailTwoToneIcon className={classes.navButton} />
                    </IconButton>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Button
                      color="inherit"
                      style={{ fontSize: "1.1rem" }}
                      onClick={handleClickLoginOpen}
                    >
                      login
                    </Button>
                    <IconButton size="small" component={Link} to={"/login"}>
                      <MailTwoToneIcon className={classes.navButton} />
                    </IconButton>
                  </React.Fragment>
                );
              }
            })()}
            <Dialog
              className={classes.dialogContainer}
              open={logoutDialogOpen}
              onClose={handleLogoutClose}
              onExited={handleLogoutClose}
            >
              <DialogContent className={classes.innerDialogContainer}>
                <DialogContentText className={classes.dialogTitle}>Are you sure you want to logout?</DialogContentText>
                <Button className={classes.bottomButtons} onClick={handleClickLogout}>Logout</Button>
                <Button className={classes.bottomButtons} onClick={handleLogoutClose}>Cancel</Button>
              </DialogContent>
            </Dialog>
            <LoginBox
              open={loginOpen}
              close={handleLoginClose}
              isLoggedIn={checkIfAuthenticated}
            />
            <IconButton size="small" onClick={handleNotifOpen}>
              <NotificationsNoneTwoToneIcon className={classes.navButton} />
            </IconButton>
            <NotificationMenu
              close={handleNotifClose}
              anchorEl={notifAnchorEl}
            />
            <IconButton size="small" component={Link} to={"/settings"}>
              <SettingsTwoToneIcon className={classes.navButton} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default Navbar;
