import React, { useState, useEffect } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { Typography, Hidden } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MobileSidebars from "./MobileSidebars";
import { CurrentUserContext } from "../App";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    margin: "auto",
    marginBottom: theme.spacing(2),
    height: "300px",
    width: "750px",
    backgroundColor: fade(theme.palette.background.paper, 0.6),
    borderRadius: "0px",
  },
  profilePicture: {
    height: "200px",
    width: "200px",
    borderRadius: "50%",
    marginLeft: theme.spacing(6),
    marginTop: "50px",
    marginBottom: "50px",
    border: `.5px solid ${theme.palette.secondary.main}`,
  },
  profileInfo: {
    marginTop: "40px",
    marginBottom: "50px",
    width: "39%",
    height: "47%",
  },
  extraProfileInfo: {
    marginTop: "40px",
    marginBottom: "50px",
    paddingLeft: "25px",
    width: "15%",
    height: "47%",
    borderLeft: `2px solid ${theme.palette.common.white}`,
  },
  bio: {
    fontStyle: "italic",
  },
  extraInfoLabel: {
    color: theme.palette.text.disabled,
    fontStyle: "bold",
  },
  atName: {
    color: theme.palette.text.secondary,
  },

}));

function ProfileGlimpse() {
  const classes = useStyles();
  const currentUser = React.useContext(CurrentUserContext);
  useEffect(() => {
    console.log("current user test");
    console.log(currentUser);
  }, [currentUser])
  return (
    <React.Fragment>
      {(() => {
        if(currentUser.id === "notloggedin") {
          return (
            <Card className={classes.container}>
            <CardMedia
            className={classes.profilePicture}
            image="https://images.unsplash.com/photo-1508310621848-9b567e9621bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1317&q=80"
            />
            <CardContent className={classes.profileInfo}>
            <Typography variant="h4">Join NewsFlash!</Typography>
            <Typography className={classes.atName} variant="subtitle1">
              Sign Up Today
            </Typography>
            <Typography className={classes.bio} variant="body1">
              {currentUser.bio}
            </Typography>
          </CardContent>
          <CardContent className={classes.extraProfileInfo}>
            {/* <Typography className={classes.extraInfoLabel}>Location</Typography> */}
            <Typography>{currentUser.location}</Typography>
            {/* <Typography className={classes.extraInfoLabel}>Joined</Typography> */}
            <Typography>{currentUser.date_joined}</Typography>
            {/* <Typography className={classes.extraInfoLabel}>Birthday</Typography> */}
            <Typography>{currentUser.birthday}</Typography>
          </CardContent>

            </Card>
          );
        } else {
          return(
        <Card className={classes.container}> 
          <CardMedia
            className={classes.profilePicture}
            image="https://images.unsplash.com/photo-1508310621848-9b567e9621bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1317&q=80"
           />
          <CardContent className={classes.profileInfo}>
            <Typography variant="h4">{`${currentUser.first_name} ${currentUser.last_name}`}</Typography>
            <Typography className={classes.atName} variant="subtitle1">
              @{currentUser.screen_name}
            </Typography>
            <Typography className={classes.bio} variant="body1">
              {currentUser.bio}
            </Typography>
          </CardContent>
          <CardContent className={classes.extraProfileInfo}>
            <Typography className={classes.extraInfoLabel}>Location</Typography>
            <Typography>{currentUser.location}</Typography>
            <Typography className={classes.extraInfoLabel}>Joined</Typography>
            <Typography>{currentUser.date_joined}</Typography>
            <Typography className={classes.extraInfoLabel}>Birthday</Typography>
            <Typography>{currentUser.birthday}</Typography>
          </CardContent>
          <Hidden lgUp>
            <MobileSidebars />
          </Hidden>
        </Card>
          );
        }
      })()}
    </React.Fragment>
  );
}

export default ProfileGlimpse;
