import React from "react";
import {
  makeStyles,
  fade,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    marginTop: theme.spacing(2),
    margin: "auto",
    backgroundColor: fade(theme.palette.background.paper, 0.6),
    borderRadius: "0px",
    color: theme.palette.text.primary,
  },
  word: {
    fontSize: "2.8rem",
  },
  pronunciation: {
    fontSize: "1.5rem",
    color: theme.palette.text.disabled,
  },
  partOfSpeech: {
    fontSize: "1.15rem",
    fontStyle: "italic"
  },
  definition: {
    fontSize: "1.15rem"
  },
  sentence: {
    fontSize: "1.15rem",
    color: theme.palette.text.secondary
  },
}));

function Definition(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography className={classes.definition}>{props.definition}</Typography>
      <Typography className={classes.sentence}>"{props.sentence}"</Typography>
    </React.Fragment>
  );
}

function DictionaryEntry() {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <CardContent>
        <Typography className={classes.word}>ci·vil·i·ty</Typography>
        <Typography className={classes.pronunciation}>/səˈvilədē/</Typography>
        <Typography className={classes.partOfSpeech}>noun</Typography>
        <Definition
          definition="formal politeness and courtesy in behavior or speech."
          sentence="I hope we can treat each other with civility and respect"
        />
        <Definition
          definition="(plural: civilities) polite remarks used in formal conversation."
          sentence="She was exchanging civilities with his mother"
        />
      </CardContent>
    </Card>
  );
}

export default DictionaryEntry;
