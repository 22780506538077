import React from "react";
import PostsContainers from "./PostsContainer";
import { makeStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import LeftSidebarDesktop from "./LeftSidebarDesktop";
import RightSidebarDesktop from "./RightSidebarDesktop";
import ProfileGlimpse from "./ProfileGlimpse";
import Chatroom from "./Chatroom";

const useStyles = makeStyles((theme) => ({
  mainPage: {
    height: "100%",
    width: "100%%",
    minWidth: "750px",
    backgroundColor: theme.palette.background.default,
    padding: "85px 10px",
    paddingBottom: "0px",
    paddingRight: "12px",
    overflow: "hidden",
  },
  mainContainer: {
    margin: "auto",
    minWidth: "750px",
    maxWidth: "1350px",
    [theme.breakpoints.up("md")]: {
      minWidth: "1325px",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "750px",
    },
  },
}));

function MainContent() {
  const classes = useStyles();

  return (
    <div className={classes.mainPage}>
      <div className={classes.mainContainer}>
        <Hidden mdDown>
          <RightSidebarDesktop />
        </Hidden>
        <Hidden mdDown>
          <LeftSidebarDesktop />
        </Hidden>
        {/* <Chatroom /> */}
        {/* <ProfileGlimpse /> */}
        <PostsContainers />
      </div>
    </div>
  );
}

export default MainContent;
