import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import SecondaryPageTitle from "./SecondaryPageTitle";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "3rem",
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginBottom: theme.spacing(2.5),
  },
  body: {
    fontSize: "1.25rem",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function Contact() {
  const classes = useStyles()
  return (
    <div>
      <SecondaryPageTitle 
        title="Contact"
      />
    </div>
  );
}

export default Contact;
